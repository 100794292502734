 

import { Row, Col, Form, Button, Card } from 'react-bootstrap';

import { useAppContext } from 'providers/AppProvider';
import { useState } from 'react';



import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const AgentsPage = () => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState<any>(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const trafficInformation = useSelector(
    (state: RootState) => state.traffic.customers
  );


  const handleInvite = (e: React.FormEvent) => {
    e.preventDefault();
    // Add your invite logic here
    console.log('Inviting:', { name, email });
  };

  console.log(trafficInformation);

  const {
    config: { isDark }
  } = useAppContext();

  const handleRowClick = (visitor: any) => {
    setSelectedVisitor(visitor);
    setIsSidePanelOpen(true);
  };

  const closeSidePanel = () => {
    setIsSidePanelOpen(false);
    setSelectedVisitor(null);
  };
 
  return (
    <>
      <Row className="gy-3 mb-6 justify-content-between">
        <Col md={12} xs="auto">
          <h2 className="mb-2 text-body-emphasis">Your Team</h2>
          <h5 className="text-body-tertiary fw-semibold">
            Invite and control your team members from this panel.
          </h5>
        </Col>
      </Row>

      <Card className="p-3" style={{maxWidth: "750px"}}>
      <Card.Body>
        <div>
          <p>Use the form below to invite a team member.</p>
        </div>
      <Form onSubmit={handleInvite}>
        <Row className="gy-3">
          <Col md={12}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={12}>
            <Button variant="primary" type="submit">
              Invite
            </Button>
          </Col>
        </Row>
      </Form></Card.Body></Card>
    </>
  );
};

export default AgentsPage;
